import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from '../../aws-exports';
import { Container, Alert} from 'theme-ui';


Amplify.configure(awsconfig);

const AuthStateApp = ({children}) => {
    //const [authState, setAuthState] = React.useState();
    //const [user, setUser] = React.useState();
    const [userError, setUserError] = React.useState({error: false, errorText: '', token: null, checkUser: false, user: null, authState: null});
    //const [isSignUp, setSignUp] = React.useState(false);
    //const [attributes, setAtrributes] = React.useState();
	//const isSignUp = React.useRef();
	//const [userCheck,setUserCheck] = React.useState(false);
	//const userCheck = false;
	const [hasRun, setRun] = React.useState(false);

	const childrenWithProps = React.Children.map(children, child => {
		// checking isValidElement is the safe way and avoids a typescript error too
		//console.log("usererror in prop: ", JSON.stringify(userError))
		const props = { user: userError.user };
		if (React.isValidElement(child)) {
			return React.cloneElement(child, props);
		}
		return child;
	});

    React.useEffect(() => {

        return onAuthUIStateChange((nextAuthState, authData) => {
            //console.log(JSON.stringify(nextAuthState));
            //console.log(JSON.stringify(authData));
            //setAuthState(nextAuthState);
            //setUser(authData);        
            //console.log("nextAuthState", nextAuthState);
			if (nextAuthState === AuthState.SignedIn ) {
				setUserError({... userError, checkUser: true, authState: nextAuthState}) ;

			} 
			else {
				setUserError({... userError, authState: nextAuthState}) ;
			}         
        });
    });
    
    React.useEffect(() => {
    	//console.log('isSignUp', isSignUp.current);
    	//console.log('authState', authState);
    	var error = false;
    	var errorText = '';
    	var token = null;
    	
    	async function checkUser() {
			
			  //console.log("usererror: ", JSON.stringify(userError))
			  var user = await Amplify.Auth.currentUserInfo()
			  //console.log("user after signin: ", user)
			  let pass = user.attributes.sub.substring(0, 13);
			  let firstName = user.attributes['custom:firstName'];
			  let lastName = user.attributes['custom:LastName'];
			  let phone_number = user.attributes.phone_number;
			  let email = user.attributes.email;
			  let query = `mutation {
				  customerAccessTokenCreate(
					  input: {
						email: "${email}",
						password: "${pass}"
					  }
					)
					{
						customerAccessToken {
						  accessToken
						  expiresAt
						}
						customerUserErrors {
						  code
						  field
						  message
						}
					  }            
				}
			  `;
			  let requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/graphql',
								'Accept': 'application/json',
								'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
					body: query
				};	
				try {
					let response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-07/graphql', requestOptions )
					response = await response.json()
					if(! response.data.customerAccessTokenCreate.customerAccessToken ) {
						//console.log("token error: ", response.data.customerAccessTokenCreate.customerUserErrors)
						if (response.data.customerAccessTokenCreate.customerUserErrors[0].code === 'UNIDENTIFIED_CUSTOMER') {
						   query = `mutation {
								  customerCreate(
									  input: {
										firstName: "${firstName}",
										lastName: "${lastName}",
										email: "${email}",
										phone: "${phone_number}",
										password: "${pass}"
									  }
									)
									{
										customer {
										  id
										}
										customerUserErrors {
										  code
										  field
										  message
										}
									  }            
								}
							`;	
							requestOptions = {
								method: 'POST',
								headers: { 'Content-Type': 'application/graphql',
											'Accept': 'application/json',
											'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
								body: query
							};	
							response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-07/graphql', requestOptions )
							response = await response.json()		
							if(! response.data.customerCreate.customer) {
								//console.log("customer create err: ", response.data.customerCreate.customerUserErrors)
								if (response.data.customerCreate.customerUserErrors[0].code === 'TAKEN') {
									error = true;
									errorText = "ALREADY EXISTS! Plz register with different email and phone"	
									setErrorState(error, errorText, user)							
								}
								else if (response.data.customerCreate.customerUserErrors[0].code === 'INVALID' && response.data.customerCreate.customerUserErrors[0].field.includes("phone")) {
								   query = `mutation {
										  customerCreate(
											  input: {
												firstName: "${firstName}",
												lastName: "${lastName}",
												email: "${email}",
												password: "${pass}"
											  }
											)
											{
												customer {
												  id
												}
												customerUserErrors {
												  code
												  field
												  message
												}
											  }            
										}
									`;	
									requestOptions = {
										method: 'POST',
										headers: { 'Content-Type': 'application/graphql',
													'Accept': 'application/json',
													'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
										body: query
									};	
									response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-07/graphql', requestOptions )
									response = await response.json()
									if(! response.data.customerCreate.customer) {
										throw "unhandled exception"
									}
									else {
										//console.log("created customer!")
										error = false;
										errorText = '';
										token = "customer created";
										setErrorState(error, errorText, token, user)										
									}										
								}
								else {
									throw "unhandled exception"
								}								
							}
							else {
								//console.log("created customer!")
								error = false;
								errorText = '';
								token = "customer created";
								setErrorState(error, errorText, token, user)							
							}					
						}
						else {
							throw "unhandled exception"
						}
					}
					else {
						error = false;
						errorText = '';
						token = response.data.customerAccessTokenCreate.customerAccessToken;
						setErrorState(error, errorText, token, user)
					}							
				}
				catch(err) {
					error = true;
					errorText = "UNKNOWN ERROR! Plz contact us at info@ismartconnect.com or try again later"
					setErrorState(error, errorText)
				}							  
			
    	}
    	
    	function setErrorState(error, errorText, token = null, user) {
			if (error) {
				signOut()
			}
			error ? setUserError({... userError, error, errorText, token, user}) : setUserError({... userError, error, errorText: '', token, user})    	
    	}
    	

		async function signOut() {
			try {
				await Amplify.Auth.signOut({ global: true });
			} catch (error) {
				//console.log('error signing out: ', error);
				error = true;
				errorText = "UNKNOWN ERROR! Plz contact us at info@ismartconnect.com or try again later"				
			}
		} 
		//if (  (authState === AuthState.SignedIn) && (!userError.token) && !userError.error) {  
		if (userError.checkUser && userError.authState === AuthState.SignedIn) {  	
    		checkUser()
    		//setRun(true) 
    	}
    	
    },[userError.checkUser]);  
    

  return  userError.authState === AuthState.SignedIn && userError.user && userError.token ? (
      <>
		{childrenWithProps}
      </>
    ) : (
    <Container>
      <AmplifyAuthenticator usernameAlias="email" style = {{
	  display: 'flex',
	  justifyContent: 'center',
	  alignItems: 'center',
	 }}>
		 <AmplifySignUp
			slot="sign-up"
			usernameAlias="email"
			formFields={[
			  {
				type: "email",
				label: "your email",
				placeholder: "email id",
				required: true,
			  },
			  {
				type: "password",
				label: "password",
				placeholder: "your password",
				required: true,
			  },
			{
				type: "custom:firstName",
				label: "First Name",
				required: true,
				placeholder: "e.g. John"
			},
			{
				type: "custom:LastName",
				label: "Last Name",
				required: true,
				placeholder: "e.g. Smith"
			},			  
			  {
				type: "phone_number",
				label: "phone",
				placeholder: "phone number",
				required: true,
			  },
			]}
		  />	 
	 	<AmplifySignIn slot="sign-in" usernameAlias="email" />
	 </AmplifyAuthenticator>
	 {userError.error && (<Alert>{userError.errorText}</Alert>)}
	</Container>
  );
}

export default AuthStateApp;