/** @jsx jsx */
import { useContext, useState, useEffect } from 'react';
import { Box, Flex, Heading, Text, Button, jsx, Label, Alert, Textarea, Grid } from 'theme-ui';
import { IoIosClose } from 'react-icons/io';
import { CartContext } from '../../provider/cart-provider';
import styles from './cart.style';
import { LocalCartContext } from '../../provider/local-cart-provider';
import WebCheckout from './webCheckout'
import AuthStateApp from '../Authenticator/AuthStateApp'
import awsconfig from '../../aws-exports';
import Amplify from 'aws-amplify';

Amplify.configure(awsconfig);

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from 'aws-amplify'

const Cart: React.FunctionComponent<{ onClick?: () => void }> = ({
	onClick,
	children,
}: any) => {

	const [isOpen, setIsOpen] = useState(false);	
	const [isAvailable, setAvailable] = useState(true);
	//const [deliveryDate, setDeliveryDate] = useState( () => (new Date()).getUTCHours() > 5 ? new Date((new Date()).setDate((new Date()).getDate() + 2)) : new Date((new Date()).setDate((new Date()).getDate() + 1)) );	
	const [deliveryDate, setDeliveryDate] = useState();
	const [isDelivery, setDelivery] = useState(true);	
	const [isGuest, setIsGuest] = useState(false);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [openCheckout, setOpenCheckout] = useState(false);
	const [note, setNote] = useState('');
	const [deliveryNote, setDeliveryNote] = useState('');	
	const [isDeliveryText, setDeliveryText] = useState(false);
	const [isNextDayDelivery, setNextDayDelivery ] = useState(true);	
	const [errorText, setErrorText] = useState('select shipping date');
	const [disabled, setDisabled] = useState(false)
	
	const { products, totalPrice, currency, clearCart } = useContext(
		LocalCartContext
	);
	const { store, addToCartAndCheckout, createToken, associateCustomer } = useContext(CartContext);
	const { checkout, updating } = store;
	const handleCheckout = async () => {
		//console.log("note: ", note)
		
		 addToCartAndCheckout((deliveryDate.getDate() + '/' + (deliveryDate.getMonth() + 1) + '/' + deliveryDate.getFullYear()), note);
		
		clearCart();
		//onClick();
	};
	const getPrice = (price: any) =>
		Intl.NumberFormat(undefined, {
			currency: currency,
			minimumFractionDigits: 2,
			style: 'currency',
		}).format(parseFloat(price ? price : 0));
		

	const checkAuth = async () => {
	//const checkAuth = () => {		
		const apiName = 'shop';
		//console.log("note: ", note)
		let dateParm = deliveryDate.toLocaleDateString("en-IN", {timeZone: 'Asia/Bangkok'}).split('/')
		const path = `/getNumOrdersByDate/farmtotruckthailand/${dateParm[2]}-${dateParm[1]}-${dateParm[0]}` ; 
		/*API.get(apiName, path)
		  .then(response => {
			// Add your code here
			//console.log("API resp: ", response)
			if (response.numOrders > 10) {
				//setDelivery(true)
				setAvailable(false)
			}
			else {
				//setDelivery(true)
				setAvailable(true)
			}
			let currdate = new Date();
			const diffTime = Math.abs(deliveryDate - currdate)/ (1000 * 60 * 60 * 24);
			if(response.utchour >= 5 && diffTime <= 1 ) {
				setNextDayDelivery(false)
			}
		  })
		  .catch(error => {
			//console.log(error);
		 });*/
		 setDisabled(true)
		const response = await API.get(apiName, path);
		setDisabled(false)
		if (response.numOrders > 10 && !response.override) {
			//setDelivery(true)
			setAvailable(false)
		}
		/*if (response.numOrders >= 0 && !response.override) { //temp for flood situtaion 
			//setDelivery(true)
			setAvailable(false)
		}*/		
		else {
			//setDelivery(true)
			setAvailable(true)
			let currdate = new Date();
			const diffTime = Math.abs(deliveryDate - currdate)/ (1000 * 60 * 60 * 24);
			//console.log(`difftime: ${diffTime}`)
			if(response.utchour >= 5 && diffTime <= 1 ) {
				setNextDayDelivery(false)
			}
			else {
				setNextDayDelivery(true)
				const user = await Amplify.Auth.currentUserInfo();
				setOpenCheckout(true);
				if (user) {
					setLoggedIn(true)
				}			
			}			
		}
	 
		/*Amplify.Auth.currentUserInfo()
			.then(user => setLoggedIn(true))
		  .catch(error => {
			console.log(error);
		 })		*/	
	}

	const checkTime = async (selectDate) => {	
		/*let myHeaders = new Headers(); 
		myHeaders.append("accept", `application/json`);

		let requestOptions = {
		  method: 'GET',
		  headers: myHeaders,	  
		  redirect: 'follow',
		};
		let response = await fetch(`https://timeapi.io/api/Time/current/zone?timeZone=Asia/Bangkok`, requestOptions);
		let responseJson = await response.json();		
		let hour = parseInt(responseJson.time.split(':')[0])
		let compareDate = new Date(responseJson.date)
		compareDate.setDate(compareDate + 1);*/
		let hour = 11;
		//if (hour >= 11 && (compareDate.getTime() <= selectDate.getTime())) {
		if (hour >= 11) {
		 	setErrorText('Next day shipping available only till 12 noon. Select next available shipping date')
		 	setDeliveryDate(selectDate)						
			//setNextDayDelivery(false)
		}
		else {
			setErrorText('')
		 	setDeliveryDate(selectDate)
		}
	}	
	
	useEffect(() => {
		if (!deliveryDate) {
			setErrorText('Select shipping date')
			return
		}
		if (!isDelivery) {
			setErrorText('Sorry! - we do not deliver on Saturdays, Sundays and Mondays - please choose another day')
			return
		}
		if (!isNextDayDelivery) {
			setErrorText('Next day shipping is available till 12 noon. Please select a later date')
			return
		}	
		if (!isAvailable) {
			setErrorText('Sorry! - no available Delivery capacity - please choose another date')
			//setErrorText('Due to flood situation we are unable to take orders between 8th - 11th Oct. Sorry for the inconvenience') //temp for flood situation		
			return
		}						
		if (! /\S/.test(deliveryNote)) {
			//setDeliveryText(true)
		 	setErrorText('Please type unavailable delivery time or dash(-) for none')
		 	return
		}
			
		setErrorText('')			
/*! isDelivery && ? 
					(<Alert>Sorry! - no available Delivery capacity - please choose another date</Alert>) : 
					! isDeliveryText ?
					(<Alert>Please type unavailable delivery time or dash(-) for none</Alert>) :	
					! isNextDayDelivery ?	
					(<Alert>Next day shipping is available till 12 noon. Please selet a later date</Alert>) :									
					(<Alert>Sorry! - we do not deliver on Saturdays, Sundays and Mondays - please choose another day</Alert>) 					
					*/				
	}, [deliveryNote, deliveryDate, isAvailable, isDelivery, isNextDayDelivery]);	
		 
	useEffect(() => {
		if(deliveryDate) {
			if( (deliveryDate.getDay() === 0) || (deliveryDate.getDay() === 1) || (deliveryDate.getDay() === 6) ) { //do not deliver on Sat Sun Mon
			//if((deliveryDate.getDay() === 0) ) { //change deliver on thu now
				//setAvailable(false)
				setDelivery(false)
				//setErrorText('Delivery day')
			}
			else {
				setDelivery(true)
				setAvailable(true)
				setNextDayDelivery(true)
			}
			//temp for 04102021 remove after
			/*else if(deliveryDate.getDay() === 1) {
				setDelivery(true)
				setAvailable(false)		
			}*/
			//temp for 13092021 remove after
			/*else {
				
				const apiName = 'shop';
				//console.log("note: ", note)
				const path = '/getNumOrdersByDate/farmtotruckthailand/' + deliveryDate.toISOString().split('T')[0]; 
				API.get(apiName, path)
				  .then(response => {
					// Add your code here
					//console.log("API resp: ", response)
					if (response.numOrders > 10) {
						setDelivery(true)
						setAvailable(false)
					}
					else {
						setDelivery(true)
						setAvailable(true)
					}
				  })
				  .catch(error => {
					//console.log(error);
				 });
			}*/
		}	
	}, [deliveryDate]);		
	
	const currentDate = new Date();
	var endDate = new Date();
	endDate = endDate.setDate(endDate.getDate() + 6)
	var startDate = new Date();
	if (startDate.getUTCHours() > 5) {
	  startDate = startDate.setDate(startDate.getDate() + 2)
	  //deliveryDate = startDate;
	}
	else {
	  startDate = startDate.setDate(startDate.getDate() + 1)
	  //deliveryDate = startDate;
	}
	//setDeliveryDate(startDate)
	
	if (openCheckout) {
		//console.log(note)
		let comboNote = note 
		comboNote = comboNote + 
`
unavailable timeslots:
` + deliveryNote;
		
		//console.log(comboNote)

		return isGuest ? (<WebCheckout onClick={onClick} setLoggedIn={setLoggedIn} deliveryDate={deliveryDate} note={comboNote}/> ) : 
		(

			<Box sx={styles.wrapper}>
			<AuthStateApp>
				<WebCheckout onClick={onClick} setLoggedIn={setLoggedIn} deliveryDate={deliveryDate} note={comboNote}/>
			</AuthStateApp>	
			{!isLoggedIn && (<Box sx={styles.buttonStyle}>		
				<Button onClick={() => {setIsGuest(!isGuest);}}>
					{updating ? 'Loading ...' : 'Continue as Guest'}
				</Button>
			</Box>)}
			</Box>			
		);	
	}
	else if (isOpen) {
		return (

				<Box id="cart" sx={styles.wrapper}>
		
					<Heading as="h2" sx={styles.title}>
						Shipping Date
						<Button title="Close" variant="text" onClick={() => {setIsOpen(false); onClick()}}>
							<IoIosClose />
						</Button>
					</Heading>	   
				  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
					  <Label>Pick a shipping date (Other provinces take 2-3 days.): <span sx={{color: 'red'}}>*</span></Label>
						  <DatePicker 
						  selected={deliveryDate}
					   minDate={startDate}
					   maxDate={endDate}
					   onChange={selectDate => { setDeliveryDate(selectDate)}}
						   placeholderText={"shipping date"}
					  />
				  </Box>
				  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
					  <Label><span sx={{fontWeight: 'bold', color: 'red'}}>INCONVENIENT&nbsp;</span> delivery hours: <span sx={{color: 'red'}}>*</span></Label>
				  	  <Textarea placeholder="During what time that you CANNOT receive the package delivery. E.g. Before 6:30 AM and after 10PM / Dash(-) for NONE" rows={3} onChange={event => { setDeliveryNote(event.target.value)}}/>
				  </Box>
				  
				  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
				  	<Textarea placeholder="Special request notes:" rows={8} onChange={event => { setNote(event.target.value)}}/>
				  </Box>
				  <Box sx={styles.buttonStyle}>
					{isAvailable  && !errorText ? 
					(<Button onClick={() => checkAuth()}>
						{disabled ? 'Loading ...' : 'checkout'}
					</Button>) :
					<Alert>{errorText}</Alert>
					} 
					{ /*! isDelivery && ? 
					(<Alert>Sorry! - no available Delivery capacity - please choose another date</Alert>) : 
					! isDeliveryText ?
					(<Alert>Please type unavailable delivery time or dash(-) for none</Alert>) :	
					! isNextDayDelivery ?	
					(<Alert>Next day shipping is available till 12 noon. Please selet a later date</Alert>) :									
					(<Alert>Sorry! - we do not deliver on Saturdays, Sundays and Mondays - please choose another day</Alert>) 					
					*/} 
				  </Box>
				  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
					  <Label><span sx={{color: 'red'}}>* </span> Required inputs</Label>
				  </Box>				  	
			</Box>	

		);	
	}
	else {
		return (
			<Box sx={styles.wrapper}>
				<Heading as="h2" sx={styles.title}>
					Cart
					<Button title="Close" variant="text" onClick={() => {setIsOpen(false); onClick()}}>
						<IoIosClose />
					</Button>
				</Heading>

				<Flex sx={styles.totalPrice}>
					<Box>
						<Text>Total Amount:</Text>
						<Heading as="h4">{getPrice(totalPrice)}</Heading>
					</Box>
				</Flex>

				<Box sx={styles.cartItems}>{children}</Box>

				<Box sx={styles.buttonStyle}>
					{products.length === 0 ? (
						<Button onClick={onClick}>
							<svg width="15.394" height="10" viewBox="0 0 15.394 10">
								<path
									id="arrow-left"
									d="M8.3,6.343,9.551,7.6,6.66,10.473l12.025.012,0,1.768L6.691,12.241,9.533,15.1,8.28,16.343,3.292,11.332Z"
									transform="translate(-3.292 -6.343)"
									fill="#fff"
								/>
							</svg>
							Back to shop
						</Button>
					) : (
						<Button onClick={() => {setIsOpen(!isOpen);}}>
							{updating ? 'Loading ...' : 'Proceed to checkout'}
						</Button>
					)}
				</Box>
			</Box>
		);	
	}

};

export default Cart;
